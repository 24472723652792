.form-wrapper {
  width: 100%;
  display: flex;
}

form {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

input, textarea {
  border: 1px solid rgba(0, 0, 0, .4);
  display: block;
  width: 100%;
  font-size: 18px;
  padding: .5rem !important;
}

input {
  margin-bottom: 1rem !important;
}

.btn.btn-dark {
  margin-left: 0;
}

@media screen and (max-width: 850px) {
  .btn.btn-dark {
    margin-left: auto;
  }
}