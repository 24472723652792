.hero-img {
  height: 100vh;
  background-color: #000;
}

.center-hero {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
}

.center-hero div {
  text-align: center;
}

.h1 > span {
  transition: top 0.3s ease;
  position: relative;
  top: 0;
}

.h1 > span:hover {
  top: -3px;
  cursor: default;
}

.hero-subheading {
  font-size: 25px;
}