#detail {
  margin-top: 65px;
}

h1, .h1 {
  font-size: 56px;
  font-weight: bold;
  display: block;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
}

h3, .h3 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
}

p {
  font-size: 18px;
  padding-top: 1rem;
}

.btn {
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #fff;
  margin: 1rem;
}

.btn.btn-dark {
  border-color: #000;
  margin-top: 3rem;
}

.btn:hover {
  background-color: rgba(255, 255, 255, .2);
}

.btn.btn-dark:hover {
  background-color: rgba(0, 0, 0, .1);
}

.page-section {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 75vh;
  justify-content: center;
}

.page-section.column {
  flex-direction: column;
}

.page-section-short {
  min-height: 50vh;
}

.left-section {
  float: left;
  height: 100%;
  width: 50%;
  padding: 2rem 3rem;
}

.right-section {
  float: right;
  height: 100%;
  width: 50%;
  padding: 2rem 3rem;
}

.section-grid-four {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  padding: 3rem 10px 10px 10px;
}

.grid-item {
  text-align: center;
}

.grid-item ul {
  /* list-style: none; */
  text-align: left;
  padding-bottom: 2rem;
  padding-top: .5rem;
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.single-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.single-section.normal {
  justify-content: baseline;
  align-items: baseline;
  margin-left: 10%;
  margin-right: 10%;
}

.contact-us-section {
  margin: 3rem 10%;
}

.bg-black {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .section-grid-four {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2em;
  }
  .membership-item {
    padding-bottom: 2em;
  }
}

@media screen and (max-width: 850px) {
  h1, .h1 {
    font-size: 44px;
  }
  h2, .h2 {
    font-size: 28px;
  }
  h3, .h3 {
    font-size: 24px;
  }
  .page-section {
    text-align: center;
  }
  .page-section li {
    text-align: left;
  }
  .btn, .btn.btn-dark {
    margin-left: auto;
    margin-right: auto;
  }
  .single-section {
    text-align: center;
    margin-top: 2rem;
  }
  .section-grid-four {
    display: block;
  }
  .grid-item {
    text-align: center;
    margin-bottom: 3rem;
  }
  .membership-item {
    border: 2px solid #000;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .right-section, .left-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .right-section .h2 {
    text-align: center;
  }
  .hide-mobile {
    display: none;
  }
}