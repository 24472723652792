.footer {
  width: 100%;
  min-height: 160px;
  background-color: #000;
  color: #fff;
  display: flex;
}

.footer-left {
  float: left;
  height: 100%;
  width: 50%;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.footer-title {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 3rem;
}

.footer-subtitle {
  font-size: 16px;
}

.footer-right {
  float: right;
  height: 100%;
  width: 50%;
  padding: 2rem 3rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: right;
}

@media screen and (max-width: 850px) {
  .footer-right {
    display: none;
  }

  .footer-left {
    width: 100%;
  }
}