.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 65px;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.logo {
  position: fixed;
  top: 5px;
  left: 1rem;
  height: 55px
}

.menu-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 60px;
  height: 60px;
  padding: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hamburger lines */
.menu-wrap .hamburger > div {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: -10px;
  height: 2px;
  width: 100%;
  background-color: inherit;
}

.menu-wrap .hamburger > div::after {
  top: 10px;
}

/* Toggler animations */
.menu-wrap .toggler.clicked + .hamburger > div {
  transform: rotate(135deg);
}

.menu-wrap .toggler.clicked + .hamburger > div::before,
.menu-wrap .toggler.clicked + .hamburger > div::after {
  top: 0;
  transform: rotate(90deg);
}

.menu-wrap .toggler.clicked:hover + .hamburger > div {
  transform: rotate(225deg);
}

/* Show Menu */
.menu-wrap .toggler.clicked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler.clicked ~ .menu > div {
  transform: scale(1);
  transition-duration: .75s;
}

.menu-wrap .toggler.clicked ~ .menu > div > div {
  opacity: 1;
  transition:  opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu > div {
  background: #fff;
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #000;
  font-size: 1.5rem;
  padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > .menu-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li > .menu-link:hover {
  color: rgba(0, 0, 0, .5)
}